html, body {
  height: 100%;
  margin: 0;
}

body {
  font-family: 'Lato', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

p:last-child {
  margin-bottom: 5rem;
}

// sticky footer
$footer-height: 210px;

.content {
  margin-bottom: -$footer-height - 40px;
  min-height: 100%;
}

footer {
  height: $footer-height;

  @media (max-width: 550px) {
    height: auto;
  }
}

.footer-push {
  height: $footer-height + 40px;
}

h1 { font-size: 3.0rem; line-height: 1.2;  letter-spacing: -.1rem;}
h2 { font-size: 2.7rem; line-height: 1.25; letter-spacing: -.1rem; }
h3 { font-size: 2.3rem; line-height: 1.3;  letter-spacing: -.1rem; }
h4 { font-size: 1.8rem; line-height: 1.35; letter-spacing: -.08rem; }
h5 { font-size: 1.4rem; line-height: 1.5;  letter-spacing: -.05rem; }
h6 { font-size: 1.1rem; line-height: 1.6;  letter-spacing: 0; }

/* Larger than phablet */
@media (min-width: 550px) {
  h1 { font-size: 4.0rem; }
  h2 { font-size: 3.4rem; }
  h3 { font-size: 2.9rem; }
  h4 { font-size: 2.4rem; }
  h5 { font-size: 1.9rem; }
  h6 { font-size: 1.2rem; }
}

a {
  color: #686868;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #dd0808;
  }
}

ul {
  list-style-type: square;
}

li {
  margin-bottom: 0;
}

.header-logo {
  margin: 20px;
}

.home {
  h1 {
    position: relative;
    top: 50%;
    transform: translateY(100%);

    @media (max-width: 550px) {
      transform: translateY(50%);
    }  
  }
}

footer {
  background: #000; 
  color: #efefef;
  padding: 20px 0;

  a {
    color: #fff; 
    font-weight: normal;
    text-decoration: none;

    &:visited {
      color: #fff; 
    }

    &:hover {
      color: #dd0808;
      font-weight: bold;
    }
  }

  h5 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;

    li {
      margin-bottom: 0;
    }
  }

  .righty {
    text-align: right;

    h5 {
      margin: 0;
    }
  }
}

.terms {
  ol {
    list-style-type: lower-alpha;

    ol {
      list-style-type: lower-roman;
    }
  }
}